<template>
<div class="profit">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ mode==0?$t('wallet.listLabel.profit'):(mode==1?$t('wallet.listLabel.nodeSaveLevel'):$t('wallet.listLabel.nodeLevel')) }}</span>
    </div>
    
    <!-- <a-radio-group v-model="mode" @change="getData(1)" button-style="solid" :style="{ margin: '8px' }">
      <a-radio-button :value="0">
        {{$t('profit.community')}}
      </a-radio-button>
      <a-radio-button :value="1">
        {{$t('profit.node')}}
      </a-radio-button>
    </a-radio-group>
    <a-list
        class="box"
        :loading="loading"
        item-layout="horizontial"
        :data-source="data"
    >
        <a-list-item class="item" slot="renderItem" slot-scope="item">
            <a-list-item-meta :title="item.created_at">
            </a-list-item-meta>
            <div>
                <span v-if="mode==1">{{$t('profit.nodeRewardType')[item.type]}}</span> 
                <span v-else>{{$t('profit.levelReward',{level:item.level})}}</span> 
                {{item.amount/1000000}}
            </div>
        </a-list-item>
        
    </a-list>
    <div v-if="showLoadingMore"
        :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
    >
        <a-spin v-if="loading" />
        <a-button v-else @click="getData">
            loading more
        </a-button>
    </div> -->

    <div class="mg30 list">
        <div class="flex box" v-for="x,i in data" :key="'r'+i">
            <div class="amount color-white">{{x.amount/1000000}}{{$t('common.currency')}}</div>
            <div class="time color-gray">{{x.created_at}}</div>
        </div>
        <div v-if="showLoadingMore"
            :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
        >
            <a-spin v-if="loading" />
            <a-button v-else type="dashed" ghost @click="getData()">
                {{$t('common.more')}}
            </a-button>
        </div>
    </div>
</div>
  
</template>
<script>
export default {
  name: "Profit",
  data() {
    return {
      loading: true,
      showLoadingMore: true,
      page:1,
      pageSize: 5,
      data: [],
      mode:this.$route.params.mode || 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(reset = 0){
        if(reset==1){
            this.pageSize = 5;
            this.page = 1;
            this.data = [];
            this.showLoadingMore = true;
        }
        this.loading = true;
        this.$http.call(this.$http.api.profits,{
            data:{mode:this.mode,page:this.page,size:this.pageSize}
        }).then(res=>{
            // console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.page++;
                this.data = this.data.concat(resp.data);
                if(resp.data.length<this.pageSize){
                    this.showLoadingMore = false;
                }
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    }
  },
};
</script>
<style lang="less" scoped>
.profit{
    padding: 30px;
    width: 100%;
    .header{
        position:relative;
        color: #fff;
        font-size: 16px;
        display:flex;
        align-items: center;
        .back{
            position:absolute;
            left: 0;
            color:#fff;
        }
        .title{
            flex: 1;
        }
    }
    .list{
        .box{
            padding: 15px;
            border-radius: 7px;
            margin-bottom: 20px;
            .amount{
                font-weight: bold;
            }
        }
    }
}
</style>
